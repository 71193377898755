import { getAccessorType } from 'typed-vuex'
import { Context } from '@nuxt/types'

// Import all your submodules
import * as user from '~/store/user'

import * as currency from '~/store/currency'
import * as watched from '~/store/watched'
import * as favourite from '~/store/favourite'
import * as cart from '~/store/cart'
import * as city from '~/store/city'
import * as catalogMenu from '~/store/catalogMenu'
import * as catalogFilter from '~/store/catalogFilter'

// Keep your existing vanilla Vuex code for state, getters, mutations, actions, plugins, etc.
const state = {
	phones: ['+38 (066) 364-19-19', '+38 (066) 364-19-19'],
}
const getters = {}
const mutations = {}
export const actions = {
	async nuxtServerInit({ dispatch }: any, ctx: Context) {
		dispatch('favourite/init')
		dispatch('city/init')
    dispatch('catalogFilter/init')
		await ctx.app.$accessor.user.init()
    dispatch('watched/init')
		await ctx.app.$accessor.cart.init()
		await dispatch('catalogMenu/init', ctx)
	},
}

// This compiles to nothing and only serves to return the correct type of the accessor
// @ts-ignore
export const accessorType = getAccessorType({
	state,
	getters,
	mutations,
	actions,
	modules: {
		user,
		currency,
		watched,
		favourite,
		cart,
		city,
		catalogMenu,
    catalogFilter
	},
})
