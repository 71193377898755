import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import CatalogFilters from "~/components/Catalog/CatalogFilters/CatalogFilters.vue";

const SLUG_COOKIE_KEY = 'catalog-slug'
const CATALOG_COOKIE_KEY = 'catalog-filters'
const PAGE_COOKIE_KEY = 'catalog-page'
const SORT_COOKIE_KEY = 'catalog-sort'

interface FilterItem {
  name: string
  values: string[]
}
export const state = () => ({
  slug: '' as string,
  catalogFilters: Object as any,
  page: 1 as number,
  sort: 'default' as string,
})

export const getters = getterTree(state, {
  getSlug(state) {
    return state.slug
  },
  getFilters(state) {
    return {...state.catalogFilters}
  },
  getPage(state) {
    return state.page
  },
  getSort(state) {
    return state.sort
  },
})

export const mutations = mutationTree(state, {
  setFilters(state, filters) {
    state.catalogFilters = {...filters}
  },
  setSlug(state, slug) {
    state.slug = slug
  },
  setPage(state, page) {
    state.page = page
  },
  setSort(state, sort) {
    state.sort = sort
  },
})

// @ts-ignore
export const actions = actionTree(
  { state, getters, mutations },
  {
    init({ state, commit }) {
      const slug = this.$cookies.get(SLUG_COOKIE_KEY)
      const currentSlug = this.$router.currentRoute.params?.slug
      if (slug === currentSlug) {
        const catalogFilters = this.$cookies.get(CATALOG_COOKIE_KEY)
        const page = this.$cookies.get(PAGE_COOKIE_KEY)
        const sort = this.$cookies.get(SORT_COOKIE_KEY)
        if (catalogFilters && Object.keys(catalogFilters).length) {
          commit('setFilters', catalogFilters)
        }
        if (page) {
          commit('setPage', page)
        }
        if (sort) {
          commit('setSort', sort)
        }

      } else {
        this.$cookies.remove(CATALOG_COOKIE_KEY)
        commit('setFilters', {})
        this.$cookies.remove(PAGE_COOKIE_KEY)
        this.$cookies.remove(SORT_COOKIE_KEY)
      }
      commit('setSlug', slug)
    },
    updateSlug({ state, commit }, {slug}) {
      if (slug !== this.app.$accessor.catalogFilter.getSlug) {
        this.app.$accessor.catalogFilter.updateFilters({filters:{}})
        this.app.$accessor.catalogFilter.updatePage({page: 1})
        this.app.$accessor.catalogFilter.updateSort({sort: 'default'})
      }
      this.$cookies.set(SLUG_COOKIE_KEY, slug)
      commit('setSlug', slug)
    },
    updateFilters({ state, commit }, {filters}) {
        this.$cookies.set(CATALOG_COOKIE_KEY, filters)
        commit('setFilters', filters)
    },
    updatePage({ state, commit }, {page}) {
      this.$cookies.set(PAGE_COOKIE_KEY, page)
      commit('setPage', page)
    },
    updateSort({ state, commit }, {sort}) {
      this.$cookies.set(SORT_COOKIE_KEY, sort)
      commit('setSort', sort)
    },
  },
)
