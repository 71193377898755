import { Type } from 'class-transformer'
import config from '../config/config'
import getImageUrl from '../helpers/image-url'
import { AttributeEntity } from './attribute.entity'
import { ILocaleValue } from './locale-value.entity'
import { ProductEntity } from './product.entity'
import { SubCategoryEntity } from './sub-category.entity'
import { TagEntity } from './tag.entity'
interface CategoryBanner {
	attributes: {
		background_color: string
		details_button: ILocaleValue
		image: string
		link_url: string
		logo: string
		sale: string
		subtitle: ILocaleValue
		title: ILocaleValue
	}
}
export class CategoryEntity {
	attachment_id: number
	@Type(() => AttributeEntity)
	attribute: AttributeEntity[]

	category_banner: CategoryBanner[]
	created_at: string
	deleted_at: null
	description: string
	id: number
	logo: []
	media: any[]
	name: ILocaleValue
	product?: ProductEntity[]
	property_level: number
	seo_block_title: string
	seo_description: string
	seo_keyphrases: string
	seo_title: string
  seo_markup_rating_value: number
  seo_markup_rating_count: number
	slug: string
	@Type(() => SubCategoryEntity)
	subcategory: SubCategoryEntity[]

	@Type(() => CategoryEntity)
	real_subcategory: CategoryEntity[]

	@Type(() => TagEntity)
	tag: TagEntity[]

	template_type: string
	updated_at: string

	get defaultImage() {
		const imageName = this.media && this.media[0] && this.media[0].attributes?.image
		return getImageUrl(imageName)
	}
}
