import { defineNuxtPlugin } from '@nuxtjs/composition-api';

export default defineNuxtPlugin(() => {
  if (process.client) {
    (window as any).helpcrunchSettings = {
      organization: 'zooua',
      appId: '437f284f-d605-425f-a17a-8b833feaeb36',
    };

    (function (w, d) {
      const hS = (w as any).helpcrunchSettings;
      if (!hS || !hS.organization) {
        return;
      }
      const widgetSrc = 'https://embed.helpcrunch.com/sdk.js';
      (w as any).HelpCrunch = function () {
        (w as any).HelpCrunch.q.push(arguments);
      };
      (w as any).HelpCrunch.q = [];

      function r() {
        if (d.querySelector(`script[src="${widgetSrc}"]`)) {
          return;
        }
        const s = d.createElement('script');
        s.async = true;
        s.type = 'text/javascript';
        s.src = widgetSrc;
        (d.body || d.head).appendChild(s);
      }

      if (d.readyState === 'complete' || hS.loadImmediately) {
        r();
      } else if ((w as any).attachEvent) {
        (w as any).attachEvent('onload', r);
      } else {
        w.addEventListener('load', r, false);
      }
    })(window, document);
  }
});
